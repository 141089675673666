require('./bootstrap');

import { createApp } from 'vue';
import 'devextreme/dist/css/dx.light.css';
import LoginComponent from './components/auth/LoginComponent';
import SubdomainComponent from  './components/subdomains/SubdomainComponent';
import DailyBackupComponent from  './components/dailyBackups/DailyBackupComponent';
import UserComponent from  './components/users/UserComponent';
import UsersCapiComponent from  './components/users/UsersCapiComponent';
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs';
import CapiLogBackupComponent from  './components/capiLogBackups/CapiLogBackupComponent';

import 'bootstrap/dist/css/bootstrap.min.css';

const app = createApp({
    components: {
        SubdomainComponent,
        DailyBackupComponent,
        UserComponent,
        LoginComponent,
        UsersCapiComponent,
        CapiLogBackupComponent
    }
})
app.mount('#app');
app.use(LaravelPermissionToVueJS);